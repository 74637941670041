<template>
    <div>
        <h1 class="page-title mb-4">Registrieren</h1>

        <v-form ref="loginform" @submit.prevent="performLogin">
            <v-text-field
                v-model="email"
                required
                outlined
                autofocus
                label="E-Mail-Adresse"
                :placeholder="!autofilled ? ' ' : null"
                autocomplete="username"
                :rules="[formRules.required('Deine E-Mail-Adresse'), formRules.valid.email]"
            />
            <v-text-field
                v-model="password"
                required
                outlined
                autocomplete="current-password"
                label="Passwort"
                :placeholder="!autofilled ? ' ' : null"
                :rules="[formRules.required('Dein Passwort')]"
                :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="toggleShowPassword()"
            />

            <v-btn :loading="loading" color="primary" large block depressed class="mb-3" type="submit">
                Registrieren
            </v-btn>
        </v-form>

        <v-row class="pa-3">
            <router-link :to="{ name: 'Login' }" class="caption">Anmelden</router-link>
            <v-spacer />
            <router-link :to="{ name: 'PasswordReset' }" class="caption">Passwort vergessen?</router-link>
        </v-row>

        <app-selection-dialog v-if="showAppSelection" :apps="apps" />
    </div>
</template>

<script>
import api from "../plugins/api";
import { sendMessage } from "../plugins/messageBus";
import formRules from "../plugins/formRules";
import AppSelectionDialog from "../components/AppSelectionDialog.vue";

export default {
    name: "Login",

    components: {
        AppSelectionDialog,
    },

    props: {
        prefilledEmail: {
            type: String,
            required: false,
            default: "",
        },
    },

    data: () => ({
        autofilled: false,
        email: "",
        password: "",
        showPassword: false,
        formRules,
        loading: false,
        apps: [],
        showAppSelection: false,
    }),

    watch: {
        email() {
            this.autofilled = true;
        },
    },

    created() {
        this.email = atob(this.prefilledEmail);
    },

    methods: {
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },

        async performLogin() {
            if (!this.$refs.loginform.validate()) return;

            this.loading = true;
            try {
                const apps = await api.login(this.email, this.password);
                if (apps.length === 1) return (window.location.href = apps[0].url);

                this.apps = apps;
                this.showAppSelection = true;
            } catch (error) {
                if (error.response.data.error === "wrong_credentials") {
                    sendMessage("Bitte überprüfe deine E-Mail-Adresse und Passwort und versuche es erneut.", "error");
                } else {
                    sendMessage(
                        "Entschuldigung, das hat leider nicht geklappt. Bitte versuche es später erneut.",
                        "error",
                    );
                }
            }
            this.loading = false;
        },
    },
};
</script>
